import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../modules/AuthContext';

function LoginForm() {
  // State for storing the username and password
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // State for storing the JWT token
  const { token, setToken } = useContext(AuthContext);

  // State for handling errors or login status
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/v1/login_p', {
        username: username,
        password: password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const jwtToken = response.data.data;
      setToken(jwtToken);
      localStorage.setItem('jwtToken', jwtToken);
      console.log('Login successful, JWT token:', response.data);
      navigate('/bcaadmin')

    } catch (err) {
      console.error('Login failed:', err.response ? err.response.data : err.message);
      setError('Login failed. Please check your credentials.');
    }
  };

  return (
    <div>
      <h2>Login Form</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>

      {token && <p>Logged in with token: {token}</p>}
    </div>
  );
}

export default LoginForm;
