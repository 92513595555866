import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom'
import axios from "axios";
import { AuthContext } from "../modules/AuthContext";

const UserLogsTable = () => {
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalLogs, setTotalLogs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedIndexes, setExpandedIndexes] = useState([]);
  const navigate = useNavigate();

  // Fetch user logs based on the current page and limit
  const fetchUserLogs = async (currentPage) => {
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('jwtToken');
      console.log(token);
      const response = await axios.get("/api/v1/me/user_logs", {
        params: { page: currentPage, limit },
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      });
      setLogs(response.data.data.logs);
      setPage(response.data.data.page);
      setTotalLogs(response.data.data.total_page); // Assume the API returns the total number of logs
    } catch (err) {
      if (err.response.data.status === 21) {
        navigate('/login');
      }
      setError(`Failed to fetch user logs ${err}`);
    } finally {
      setLoading(false);
    }
  };

  // Load logs when the component mounts or the page/limit changes
  useEffect(() => {
    fetchUserLogs(page);
  }, [page, limit]);

  // Handle page navigation
  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };
  const toggleExpand = (index) => {
    if (expandedIndexes.includes(index)) {
      // If already expanded, collapse it
      setExpandedIndexes(expandedIndexes.filter(i => i !== index));
    } else {
      // Expand the section
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  return (
    <div style={{textAlign: 'start'}}>
      <h1>User Logs</h1>

      {/* Display loading state */}
      {loading && <p>Loading logs...</p>}

      {/* Display error state */}
      {error && <p>{error}</p>}

      {/* Display logs in a table */}
      {!loading && logs.length > 0 && 
        <div>
          <div style={{display: 'flex', padding: '15px'}}>
            <div style={{flex: '0 0 6rem'}}>Log #</div>
            <div style={{flex: '0 0 8rem'}}>User ID</div>
            <div style={{flex: '0 0 10rem'}}>輸出時間(UTC+8)</div>
            <div style={{flex: '0 0 8rem'}}>觀測點數量</div>
            <div style={{flex: '0 0 8rem'}}>輸出點數（戶數）</div>
            <div style={{flex: '0 0 8rem'}}>輸出後剩餘額度</div>
            <div style={{flex: '1 0 8rem'}}>地址列表</div>
          </div>
          {logs.map((logItem, index) => {
            const logData = JSON.parse(logItem.log);  // Parse the JSON from the log field
    
            // Calculate the sum of 'num' in 'assessRecordDetail'
            const numSum = logData.export_amount.assessRecordDetail.reduce((sum, record) => sum + record.num, 0);
    
            return (
              <div key={logItem.ID} style={{ padding: '10px', border: '1px solid #ccc', display: 'flex' }}>
                <div style={{flex: '0 0 6rem'}}>{logItem.ID}</div>
                <div style={{flex: '0 0 8rem', borderLeft:'1px solid #ccc'}}>{logItem.user_id}</div>
    
                <div style={{flex: '0 0 10rem', borderLeft:'1px solid #ccc'}}>{new Date(logItem.CreatedAt).toLocaleString()}</div>
                <div style={{flex: '0 0 8rem', borderLeft:'1px solid #ccc'}}>{numSum}</div>
                <div style={{flex: '0 0 8rem', borderLeft:'1px solid #ccc'}}>{logData.export_amount.assessRecordDetail.length}</div>
                <div style={{flex: '0 0 8rem', borderLeft:'1px solid #ccc'}}>{logData.remain_quota.Amount}</div>
    
                <div style={{flex: '1 0 auto', borderLeft:'1px solid #ccc'}}>
                  {/*<button onClick={() => toggleExpand(index)}>
                    {expandedIndexes.includes(index) ? 'Collapse Addresses' : 'Expand Addresses'}
                  </button>*/}
                  {/*{expandedIndexes.includes(index) && (*/}
                    <div style={{ marginTop: '10px' }}>
                      {logData.export_amount.assessRecordDetail.map((record, i) => (
                        <div key={i}>{record.address}</div>
                      ))}
                    </div>
                  {/*)}*/}
                </div>
              </div>
            );
          })}
        </div>
      }

      {/* Pagination Controls */}
      <div style={{ marginTop: "20px" }}>
        <button onClick={handlePreviousPage} disabled={page === 1}>
          Previous
        </button>
        <span style={{ margin: "0 10px" }}>Page: {page}</span>
        <button
          onClick={handleNextPage}
          disabled={page === totalLogs} // Disable next if we have fewer items than the limit
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default UserLogsTable;