import './App.css';
import PasswordReset from './PasswordReset';
import Home from './Home';
import { Routes, Route, Link } from 'react-router-dom';
import LoginForm from './Login';
import UserLogsTable from './Admin/UserLogs';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/password" element={<PasswordReset />}/>
        <Route path="/login" element={<LoginForm />}/>
        <Route path="/bcaadmin" element={<UserLogsTable />}/>
      </Routes>
    </div>
  );
}

export default App;
